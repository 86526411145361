<div class="container">
    <div class="row wrapper-info">
        <div class="col-md-3 col-12"></div>
        <div class="col-md-6 col-12 text-center">
            <img class="img-check" src="../../../../assets/images/check-thank.png" alt="">
            <div class="wrapper-content">
                <h1>Tus datos fueron enviados con éxito</h1>
            <p>Una vez que recibimos tus datos serán procesados por nuestro equipo y te estaremos dando respuesta lo más pronto posible, Gracias por querer ser parte de Swayp.
            </p>
            </div>
            
            <button class="btn btn-primary" mat-button routerLink="/">Regresar</button>
        </div>
        <div class="col-md-3 col-12"></div>
    </div>
</div>

