<!-- Hero Start -->
<section class="bg-half-170 bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row mt-5 justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="pages-heading">
          <h4 class="title mb-0"> Blog Listing & Sidebar </h4>
        </div>
      </div> <!--end col-->
    </div><!--end row-->

    <div class="position-breadcrumb">
      <nav aria-label="breadcrumb" class="d-inline-block">
        <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
          <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
          <li class="breadcrumb-item"><a href="javascript:void(0)">Page</a></li>
          <li class="breadcrumb-item"><a href="javascript:void(0)">Blog</a></li>
          <li class="breadcrumb-item active" aria-current="page">Blog List with Sidebar</li>
        </ul>
      </nav>
    </div>
  </div> <!--end container-->
</section><!--end section-->

<div class="position-relative">
  <div class="shape overflow-hidden text-color-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- Hero End -->

<!--Blog Lists Start-->
<section class="section">
  <div class="container">
    <div class="row">
      <!-- Blog Post Start -->
      <div class="col-lg-8 col-12">
        <div class="row">
          <div class="col-12 mb-4 pb-2" *ngFor="let data of blogListData">
            <div class="card blog rounded border-0 shadow overflow-hidden">
              <div class="row align-items-center g-0">
                <div class="col-md-6">
                  <img src="{{data.image}}" class="img-fluid" alt="">
                  <div class="overlay bg-dark"></div>
                  <div class="author">
                    <small class="text-light user d-block"><i class="uil uil-user"></i> {{data.name}}</small>
                    <small class="text-light date"><i class="uil uil-calendar-alt"></i> {{data.date}}</small>
                  </div>
                </div>
                <!--end col-->

                <div class="col-md-6">
                  <div class="card-body content">
                    <h5><a href="javascript:void(0)" class="card-title title text-dark">{{data.title}}</a></h5>
                    <p class="text-muted mb-0">{{data.content}}</p>
                    <div class="post-meta d-flex justify-content-between mt-3">
                      <ul class="list-unstyled mb-0">
                        <li class="list-inline-item me-2 mb-0"><a href="javascript:void(0)" class="text-muted like"><i
                              class="uil uil-heart me-1"></i>{{data.like}}</a></li>
                        <li class="list-inline-item"><a href="javascript:void(0)" class="text-muted comments"><i
                              class="uil uil-comment me-1"></i>{{data.message}}</a></li>
                      </ul>
                      <a routerLink="/page-blog-detail" class="text-muted readmore">Read More <i
                          class="uil uil-angle-right-b align-middle"></i></a>
                    </div>
                  </div>
                </div>
                <!--end col-->
              </div>
              <!--end row-->
            </div>
            <!--end blog post-->
          </div>
          <!--end col-->
          <!-- PAGINATION START -->
          <div class="col-12">
            <ul class="pagination justify-content-center mb-0">
              <li class="page-item"><a class="page-link" href="javascript:void(0)" aria-label="Previous">Prev</a></li>
              <li class="page-item active"><a class="page-link" href="javascript:void(0)">1</a></li>
              <li class="page-item"><a class="page-link" href="javascript:void(0)">2</a></li>
              <li class="page-item"><a class="page-link" href="javascript:void(0)">3</a></li>
              <li class="page-item"><a class="page-link" href="javascript:void(0)" aria-label="Next">Next</a></li>
            </ul>
          </div>
          <!--end col-->
          <!-- PAGINATION END -->
        </div>
        <!--end row-->
      </div>
      <!--end col-->
      <!-- Blog Post End -->

      <!-- START SIDEBAR -->
      <div class="col-lg-4 col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
        <div class="card border-0 sidebar sticky-bar ms-lg-4">
            <div class="card-body p-0">
                <!-- Author -->
                <div class="text-center">
                    <span class="bg-light d-block py-2 rounded shadow text-center h6 mb-0">
                        Author
                    </span>

                    <div class="mt-4">
                        <img src="assets/images/client/03.jpg" class="img-fluid avatar avatar-medium rounded-pill shadow-md d-block mx-auto" alt="">

                        <a routerLink="/blog-about" class="text-primary h5 mt-4 mb-0 d-block">Cristina Jota</a>
                        <small class="text-muted d-block">Photographer & Blogger</small>
                    </div>
                </div>
                <!-- Author -->

                <!-- RECENT POST -->
                <div class="widget mt-4">
                    <span class="bg-light d-block py-2 rounded shadow text-center h6 mb-0">
                        Recent Post
                    </span>

                    <div class="mt-4">
                        <div class="d-flex align-items-center">
                            <img src="assets/images/blog/01.jpg" class="avatar avatar-small rounded" style="width: auto;" alt="">
                            <div class="flex-1 ms-3">
                                <a href="javascript:void(0)" class="d-block title text-dark">Consultant Business</a>
                                <span class="text-muted">15th April 2021</span>
                            </div>
                        </div>

                        <div class="d-flex align-items-center mt-3">
                            <img src="assets/images/blog/02.jpg" class="avatar avatar-small rounded" style="width: auto;" alt="">
                            <div class="flex-1 ms-3">
                                <a href="javascript:void(0)" class="d-block title text-dark">Grow Your Business</a>
                                <span class="text-muted">15th April 2021</span>
                            </div>
                        </div>
                        
                        <div class="d-flex align-items-center mt-3">
                            <img src="assets/images/blog/03.jpg" class="avatar avatar-small rounded" style="width: auto;" alt="">
                            <div class="flex-1 ms-3">
                                <a href="javascript:void(0)" class="d-block title text-dark">Look On The Glorious Balance</a>
                                <span class="text-muted">15th April 2021</span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- RECENT POST -->

                <!-- TAG CLOUDS -->
                <div class="widget mt-4">
                    <span class="bg-light d-block py-2 rounded shadow text-center h6 mb-0">
                        Tagclouds
                    </span>
                    
                    <div class="tagcloud text-center mt-4">
                        <a href="jvascript:void(0)" class="rounded">Business</a>
                        <a href="jvascript:void(0)" class="rounded">Finance</a>
                        <a href="jvascript:void(0)" class="rounded">Marketing</a>
                        <a href="jvascript:void(0)" class="rounded">Fashion</a>
                        <a href="jvascript:void(0)" class="rounded">Bride</a>
                        <a href="jvascript:void(0)" class="rounded">Lifestyle</a>
                        <a href="jvascript:void(0)" class="rounded">Travel</a>
                        <a href="jvascript:void(0)" class="rounded">Beauty</a>
                        <a href="jvascript:void(0)" class="rounded">Video</a>
                        <a href="jvascript:void(0)" class="rounded">Audio</a>
                    </div>
                </div>
                <!-- TAG CLOUDS -->
                
                <!-- SOCIAL -->
                <div class="widget mt-4">
                    <span class="bg-light d-block py-2 rounded shadow text-center h6 mb-0">
                        Social Media
                    </span>

                    <ul class="list-unstyled social-icon social text-center mb-0 mt-4">
                        <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded"><i-feather name="facebook" class="fea icon-sm fea-social"></i-feather></a></li>
                        <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded"><i-feather name="instagram" class="fea icon-sm fea-social"></i-feather></a></li>
                        <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded"><i-feather name="twitter" class="fea icon-sm fea-social"></i-feather></a></li>
                        <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded"><i-feather name="linkedin" class="fea icon-sm fea-social"></i-feather></a></li>
                        <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded"><i-feather name="github" class="fea icon-sm fea-social"></i-feather></a></li>
                        <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded"><i-feather name="youtube" class="fea icon-sm fea-social"></i-feather></a></li>
                        <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded"><i-feather name="gitlab" class="fea icon-sm fea-social"></i-feather></a></li>
                    </ul><!--end icon-->
                </div>
                <!-- SOCIAL -->
            </div>
        </div>
    </div><!--end col-->
    <!-- END SIDEBAR -->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section -->
<!--Blog Lists End-->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->