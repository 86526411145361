import { Component } from '@angular/core';

@Component({
  selector: 'app-auth-login-bg-video',
  templateUrl: './auth-login-bg-video.component.html',
  styleUrls: ['./auth-login-bg-video.component.css']
})
export class AuthLoginBgVideoComponent {
  year = new Date().getFullYear()
}
