import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { Cities } from "src/app/models/area.interface";
import { AuthService } from "src/app/services/auth.service";
import { ServicesService } from "src/app/services/services.service";
import { WizardService } from "src/app/services/wizard.service";
import { checkMessengerEmailExistsConfim } from "src/app/shared/form-extensions/checkEmailExistsConfirm";
import { checkMessengerPhoneExistsConfirm } from "src/app/shared/form-extensions/checkPhoneExistsConfirm";
import { HabeasDataComponent } from "../politics/habeas-data/habeas-data.component";
import { Messenger } from "src/app/models/messenger.interface";
import { FileUpload } from "src/app/models/fileupload.interface";
import { UploadService } from "src/app/services/upload.service";

@Component({
  selector: "app-wizard-messen",
  templateUrl: "./wizard-messen.component.html",
  styleUrls: ["./wizard-messen.component.css"],
})
export class WizardMessenComponent implements OnInit {
  formData!: FormGroup;
  file_store: File;
  timerSpinner: boolean;
  cities: Cities[];
  searchText: string = "";
  cities$;

  constructor(
    private formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,
    private wizardService: WizardService,
    private services: ServicesService,
    private authService: AuthService,
    private router: Router,
    private dialog: MatDialog,
    private uploadService: UploadService
  ) {
    this.initLoadResourses();
  }

  initLoadResourses() {
    this.authService.logIn().subscribe((response) => {
      this.cities$ = this.services.getCities(response.token);
    });
  }

  ngOnInit(): void {
    this.formData = new FormGroup({
      basic: this.formBuilder.group({
        identification: new FormControl("", [Validators.required, Validators.minLength(8), Validators.pattern(/-\d$/)]),
        name: new FormControl("", Validators.required),
        gender: new FormControl("", Validators.required),
      }),
      contact: this.formBuilder.group({
        phone: new FormControl("", {
          asyncValidators: checkMessengerPhoneExistsConfirm(this.wizardService),
          updateOn: "blur",
          validators: Validators.required,
        }),
        emailContact: new FormControl("", {
          asyncValidators: checkMessengerEmailExistsConfim(this.wizardService),
          updateOn: "blur",
          validators: [Validators.email, Validators.required],
        }),
      }),
      cities: new FormControl("", [Validators.required]),
      address: new FormControl("", Validators.required),
      checkPolicies: new FormControl(false, [Validators.requiredTrue]),
      fileName: new FormControl("", Validators.required),
    });
  }

  sendData() {
    this.timerSpinner = true;
    const formDataUpload = new FormData();
    formDataUpload.append("attachment", this.file_store);
    this.uploadService.uploadFileStorage(formDataUpload).subscribe({
      next: (upload: FileUpload) => {
        if (upload.id !== "") {
          const user: Messenger = {
            identification: this.basicGroup.get("identification")?.value,
            name: this.basicGroup.get("name")?.value,
            gender: this.basicGroup.get("gender")?.value,
            checkPolicies: this.formData.get("checkPolicies").value,
            city: this.citiesControl.value,
            phone: this.contactGroup.get("phone")?.value,
            address: this.getControlAddress.value,
            emailContact: this.emailControl.value,
            attachment: upload,
          };
          this.formData.markAsPending();
          this.wizardService.wizardMessengerServiceSend(user).subscribe({
            next: (res) => {
              this._snackBar.open(res.message, "Aceptar", {
                duration: 5000,
              });
              this.timerSpinner = false;
              this.formData.disabled;
              this.router.navigate(["onboard-thank"]);
            },
            error: (err) => {
              console.log(err);
              this._snackBar.open(err.error.message, "Aceptar", {
                duration: 5000,
              });
              this.timerSpinner = false;
            },
          });
        } else {
          this._snackBar.open("Error creando el archivo", "Aceptar", {
            duration: 5000,
          });
          this.timerSpinner = false;
        }
      },
      error: (err) => {
        console.log(err);
        console.log(err);
        this._snackBar.open(err.error.message, "Aceptar", {
          duration: 5000,
        });
        this.timerSpinner = false;
      },
    });
  }

  get basicGroup(): FormControl {
    return this.formData.get("basic") as FormControl;
  }

  get getControlIdentification() : FormControl{
    return this.basicGroup.get('identification') as FormControl
  }

  get contactGroup(): FormControl {
    return this.formData.get("contact") as FormControl;
  }

  get emailControl(): FormControl {
    return this.contactGroup.get("emailContact") as FormControl;
  }
  get phoneCheck(): FormControl {
    return this.contactGroup.get("phone") as FormControl;
  }

  get getControlAddress(): FormControl {
    return this.formData.get("address") as FormControl;
  }

  get nameControl(): FormControl {
    return this.basicGroup.get("name") as FormControl;
  }

  get citiesControl(): FormControl {
    return this.formData.get("cities") as FormControl;
  }

  handleFileInputChange(l: FileList): void {
    const fileName = this.formData.get("fileName");
    if (l.length) {
      const f = l[0];
      this.file_store = f;
      fileName.patchValue(`${f.name}`);
    } else {
      fileName.patchValue("");
    }
  }

  openDialog() {
    const dialogRef = this.dialog.open(HabeasDataComponent);
    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }
}
