import { AbstractControl, AsyncValidatorFn, ValidationErrors } from "@angular/forms";
import { Observable, map } from "rxjs";
import { WizardService } from "src/app/services/wizard.service";

export function checkMessengerEmailExistsConfim (wizardService:WizardService) : AsyncValidatorFn {
    return (control:AbstractControl): Observable<ValidationErrors | null> => {
        return wizardService
        .wizardMessengerCheckEmailExists(control.value)
        .pipe(
            map((messenger) => Boolean(messenger && messenger.emailContact === control.value) 
             ? { checkMessengerEmailExistsConfim : true} : null )
        )
    }
}

export function checkClientsEmailExistsConfim (wizardService:WizardService) : AsyncValidatorFn {
    return (control:AbstractControl): Observable<ValidationErrors | null> => {
        return wizardService
        .wizardClientsCheckEmailExists(control.value)
        .pipe(
            map((client) => Boolean(client && client.emailContact === control.value) 
             ? { checkClientsEmailExistsConfim : true} : null )
        )
    }
}