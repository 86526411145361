<!-- Hero Start -->
<section class="bg-half-170 bg-light d-table w-100" id="home">
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="pages-heading">
                    <h4 class="title mb-0"> Modals </h4>
                </div>
            </div> <!--end col-->
        </div><!--end row-->

        <div class="position-breadcrumb">
            <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                    <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Components</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Modals</li>
                </ul>
            </nav>
        </div>
    </div> <!--end container-->
</section><!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
    <div class="shape overflow-hidden text-color-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!--Shape End-->

<section class="section">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-4 d-md-block d-none">
                <app-component-sidebar></app-component-sidebar>
            </div>

            <div class="col-lg-9 col-md-8 col-12">
                <div class="row">
                    <!-- Modal Start -->
                    <div class="col-12">
                        <div class="component-wrapper rounded shadow">
                            <div class="p-4 border-bottom">
                                <h5 class="title mb-0"> Modal </h5>
                            </div>

                            <div class="p-4">
                                <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#LoginForm" class="btn btn-primary m-1"> Click Here</a>
                                <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#login-popup" class="btn btn-primary m-1"> Login Popup</a>
                                <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#subscribe-popup" class="btn btn-primary m-1">Subscribe Popup</a>
                                <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#wishlist" class="btn btn-primary m-1">Wishlist Popup</a>
                                <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#empty-cart" class="btn btn-primary m-1">Empty Cart Popup</a>
                            </div>

                            <!-- Modal Content Start -->
                            <div class="modal fade" id="LoginForm" tabindex="-1" aria-labelledby="LoginForm-title" aria-hidden="true">
                                <div class="modal-dialog modal-dialog-centered">
                                    <div class="modal-content rounded shadow border-0">
                                        <div class="modal-header border-bottom">
                                            <h5 class="modal-title" id="LoginForm-title">Modal title</h5>
                                            <button type="button" class="btn btn-icon btn-close" data-bs-dismiss="modal" id="close-modal"><i class="uil uil-times fs-4 text-dark"></i></button>
                                        </div>
                                        <div class="modal-body">
                                            <div class="bg-white p-3 rounded box-shadow">
                                                <p class="text-muted mb-0">Dummy text is also used to demonstrate the appearance of different typefaces and layouts, and in general the content of dummy text is nonsensical. Due to its widespread use as filler text for layouts, non-readability is of great importance: human perception is tuned to recognize certain patterns and repetitions in texts. If the distribution of letters and 'words' is random, the reader will not be distracted from making a neutral judgement on the visual impact and readability of the typefaces (typography), or the distribution of text on the page (layout or type area). For this reason, dummy text usually consists of a more or less random series of words or syllables. This prevents repetitive patterns from impairing the overall visual impression and facilitates the comparison of different typefaces. Furthermore, it is advantageous when the dummy text is relatively realistic so that the layout impression of the final publication is not compromised.</p>                                                        
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                            <button type="button" class="btn btn-primary">Save changes</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Modal Content End -->

                            <!-- Login Form Start -->
                            <div class="modal fade" id="login-popup" tabindex="-1" aria-hidden="true">
                                <div class="modal-dialog  modal-lg modal-dialog-centered">
                                    <div class="modal-content rounded shadow border-0">
                                        <div class="modal-body p-0">
                                            <div class="container-fluid px-0">
                                                <div class="row align-items-center g-0">
                                                    <div class="col-lg-6 col-md-5">
                                                        <img src="assets/images/course/online/ab02.jpg" class="img-fluid" alt="">
                                                    </div><!--end col-->

                                                    <div class="col-lg-6 col-md-7">
                                                        <form class="login-form p-4">
                                                            <div class="row">
                                                                <div class="col-lg-12">
                                                                    <div class="mb-3">
                                                                        <label class="form-label">Your Email <span class="text-danger">*</span></label>
                                                                        <div class="form-icon position-relative">
                                                                            <i-feather name="user" class="fea icon-sm icons"></i-feather>
                                                                            <input type="email" class="form-control ps-5" placeholder="Email" name="email" required="">
                                                                        </div>
                                                                    </div>
                                                                </div><!--end col-->
                        
                                                                <div class="col-lg-12">
                                                                    <div class="mb-3">
                                                                        <label class="form-label">Password <span class="text-danger">*</span></label>
                                                                        <div class="form-icon position-relative">
                                                                            <i-feather name="key" class="fea icon-sm icons"></i-feather>
                                                                            <input type="password" class="form-control ps-5" placeholder="Password" required="">
                                                                        </div>
                                                                    </div>
                                                                </div><!--end col-->
                        
                                                                <div class="col-lg-12">
                                                                    <div class="d-flex justify-content-between">
                                                                        <div class="mb-3">
                                                                            <div class="form-check">
                                                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault4">
                                                                                <label class="form-check-label" for="flexCheckDefault4">Remember me</label>
                                                                            </div>
                                                                        </div>
                                                                        <p class="forgot-pass mb-0"><a routerLink="/auth-re-password" class="text-dark fw-bold">Forgot password ?</a></p>
                                                                    </div>
                                                                </div><!--end col-->
                        
                                                                <div class="col-lg-12 mb-0">
                                                                    <div class="d-grid">
                                                                        <button class="btn btn-primary">Sign in</button>
                                                                    </div>
                                                                </div><!--end col-->
                        
                                                                <div class="col-12 text-center">
                                                                    <p class="mb-0 mt-3"><small class="text-dark me-2">Don't have an account ?</small> <a routerLink="/auth-signup" class="text-dark fw-bold">Sign Up</a></p>
                                                                </div><!--end col-->
                                                            </div><!--end row-->
                                                        </form>
                                                    </div><!--end col-->
                                                </div><!--end row-->
                                            </div><!--end container-->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Login Form End -->

                            <!-- Subscribe popup Start -->
                            <div class="modal fade" id="subscribe-popup" tabindex="-1" aria-hidden="true">
                                <div class="modal-dialog  modal-lg modal-dialog-centered">
                                    <div class="modal-content rounded shadow border-0">
                                        <div class="modal-body p-0" style="background: url('assets/images/subscribe.png') no-repeat;">
                                            <button type="button" class="btn-close float-end m-2" data-bs-dismiss="modal" aria-label="Close"></button>
                                            <div class="clearfix"></div>
                                            <div class="container-fluid px-0">
                                                <div class="row align-items-center g-0">
                                                    <div class="col-lg-6 offset-lg-6">
                                                        <div class="pb-5 pt-4 px-4">
                                                            <h6 class="text-muted">Be the first to know daily updates</h6>

                                                            <h4>Signup for daily updates</h4>
                                                            <p class="text-muted mb-0">Subscribe to the Landrick mailing list to receive updates on new arrivals, special offers and our promotions.</p>
                                                            <form class="mt-4">
                                                                <div class="row">
                                                                    <div class="col-lg-12">
                                                                        <div class="foot-subscribe mb-3">
                                                                            <div class="form-icon position-relative">
                                                                                <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                                                                                <input type="email" name="email" id="daily-updates" class="form-control ps-5 rounded" placeholder="Your email : " required>
                                                                            </div>
                                                                        </div>
                                                                    </div><!--end col-->

                                                                    <div class="col-lg-12">
                                                                        <div class="d-grid">
                                                                            <input type="submit" id="submitsubscribe" name="send" class="btn btn-primary" value="Subscribe">
                                                                        </div>
                                                                    </div><!--end col-->

                                                                    <div class="col-12">
                                                                        <div class="text-center">
                                                                            <p class="mb-0 mt-3"><small class="text-dark me-2">Already have an account ?</small> <a href="javascript:void(0)" class="text-dark fw-bold">Sign in</a></p>
                                                                        </div>
                                                                    </div><!--end col-->
                                                                </div><!--end row-->
                                                            </form>
                                                        </div>
                                                    </div><!--end col-->
                                                </div><!--end row-->
                                            </div><!--end container-->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Subscribe popup End -->

                            <!-- Wishlist Popup Start -->
                            <div class="modal fade" id="wishlist" tabindex="-1" aria-hidden="true">
                                <div class="modal-dialog modal-dialog-centered">
                                    <div class="modal-content rounded shadow border-0">
                                        <div class="modal-body py-5">
                                            <div class="text-center">
                                                <div class="icon d-flex align-items-center justify-content-center bg-soft-danger rounded-circle mx-auto" style="height: 95px; width:95px;">
                                                    <h1 class="mb-0"><i class="uil uil-heart-break align-middle"></i></h1>
                                                </div>
                                                <div class="mt-4">
                                                    <h4>Your wishlist is empty.</h4>
                                                    <p class="text-muted">Create your first wishlist request...</p>
                                                    <div class="mt-4">
                                                        <a href="javascript:void(0)" class="btn btn-outline-primary">+ Create new wishlist</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Wishlist Popup End -->

                            <!-- Wishlist Popup Start -->
                            <div class="modal fade" id="empty-cart" tabindex="-1" aria-hidden="true">
                                <div class="modal-dialog modal-dialog-centered">
                                    <div class="modal-content rounded shadow border-0">
                                        <div class="modal-body py-5">
                                            <div class="text-center">
                                                <img src="assets/images/shop/cart.png" class="avatar avatar-medium rounded-circle shadow" alt="">
                                                <div class="mt-4">
                                                    <h4>Your cart is empty.</h4>
                                                    <p class="text-muted">Looks like you haven't made your choice yet...</p>
                                                    <div class="mt-4">
                                                        <a href="javascript:void(0)" class="btn btn-primary">Start Shopping</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Wishlist Popup End -->
                        </div>
                    </div><!--end col-->
                    <!-- Modal End -->
                </div><!--end row-->
            </div><!--end col-->
        </div>
    </div>
</section>

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
  </a>
  <!-- Back to top -->

