import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpResponse } from '../models/httpResponse.interface';
import { Messenger } from '../models/messenger.interface';
import { Client } from '../models/clients.interface';

@Injectable({
  providedIn: 'root'
})
export class WizardService {

  private url = environment.API_PROD

  constructor(private httpClient: HttpClient) { }

  wizardMessengerServiceSend(formData: Messenger){
    return this.httpClient.post<HttpResponse>(`${this.url}onboarding-teams`, formData);
  }

  wizardClientsServiceSend(formData: Client){
    return this.httpClient.post<HttpResponse>(`${this.url}onboarding-clients`, formData);
  }

  wizardMessengerCheckEmailExists(email:string){
    return this.httpClient.get<Messenger>(`${this.url}checkMessengerEmail/${email}`);
  }

  wizardMessengercheckPhoneExists(phone:string){
    return this.httpClient.get<Messenger>(`${this.url}checkMessengerPhone/${phone}`);
  }  

  wizardClientsCheckEmailExists(email:string){
    return this.httpClient.get<Client>(`${this.url}checkClientEmail/${email}`);
  }

  wizardClientsCheckPhoneExists(phone:string){
    return this.httpClient.get<Client>(`${this.url}checkClientPhone/${phone}`);
  } 
}
