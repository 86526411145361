import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-habeas-data',
  templateUrl: './habeas-data.component.html',
  styleUrls: ['./habeas-data.component.css']
})
export class HabeasDataComponent {
  constructor(public dialogRef: MatDialogRef<HabeasDataComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
    }){}
}
