import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-blog-detail-two',
  templateUrl: './page-blog-detail-two.component.html',
  styleUrls: ['./page-blog-detail-two.component.css']
})

/**
 * page Blog-Detail-Two Component
 */
export class PageBlogDetailTwoComponent implements OnInit {

      // Set Topbar Option
  Menuoption = 'center';
  Settingicon = true
  /**
   * Nav Light Class Add
   */
  navClass = 'nav-light';

  constructor() { }

  ngOnInit(): void {
  }


}
