import { Component } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { Area, Cities } from "src/app/models/area.interface";
import { Sector } from "src/app/models/sector.interface";
import { Service } from "src/app/models/services.interface";
import { AuthService } from "src/app/services/auth.service";
import { ServicesService } from "src/app/services/services.service";
import { WizardService } from "src/app/services/wizard.service";
import { checkClientsEmailExistsConfim } from "src/app/shared/form-extensions/checkEmailExistsConfirm";
import { checkClientPhoneExistsConfirm } from "src/app/shared/form-extensions/checkPhoneExistsConfirm";
import { HabeasDataComponent } from "../politics/habeas-data/habeas-data.component";
import { UploadService } from "src/app/services/upload.service";
import { FileUpload } from "src/app/models/fileupload.interface";
import { Client } from "src/app/models/clients.interface";

@Component({
  selector: "app-wizard-ecommerce",
  templateUrl: "./wizard-ecommerce.component.html",
  styleUrls: ["./wizard-ecommerce.component.css"],
})
export class WizardEcommerceComponent {
  formData!: FormGroup;
  file_store: File;
  timerSpinner: boolean;
  cities: Cities[];
  searchText: string = "";
  cities$;
  private utmReferal: string

  constructor(
    private formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,
    private wizardService: WizardService,
    private services: ServicesService,
    private authService: AuthService,
    private router: Router,
    private dialog: MatDialog,
    private uploadService: UploadService,
    private route: ActivatedRoute,
  ) {
    this.initLoadResourses();
  }

  initLoadResourses() {
    this.authService.logIn().subscribe((response) => {
      this.cities$ = this.services.getCities(response.token);
    });
  }

  ngOnInit(): void {
    this.formData = new FormGroup({
      basic: this.formBuilder.group({
        identification: new FormControl("", [Validators.required, Validators.minLength(8), Validators.pattern(/-\d$/)]),
        name: new FormControl("", Validators.required),
        sector: new FormControl("", Validators.required),
        employees: new FormControl("", Validators.required),
      }),
      contact: this.formBuilder.group({
        phone: new FormControl("", {
          asyncValidators: checkClientPhoneExistsConfirm(this.wizardService),
          updateOn: "blur",
          validators: Validators.required,
        }),
        emailContact: new FormControl("", {
          asyncValidators: checkClientsEmailExistsConfim(this.wizardService),
          updateOn: "blur",
          validators: [Validators.email, Validators.required],
        }),
        web: new FormControl("", [Validators.required]),
      }),
      service: new FormControl("", [Validators.required]),
      cities: new FormControl("", [Validators.required]),
      fileName: new FormControl("", Validators.required),
      address: new FormControl("", Validators.required),
      checkPolicies: new FormControl(false, [Validators.requiredTrue]),
    });

    const id = this.route.snapshot.queryParamMap.get("id")
    const utm = this.route.snapshot.queryParamMap.get("utm")

    if (utm && id) {
      this.utmReferal = `utm=${utm}:id=${id}`
    }
  }

  sendData() {
    this.timerSpinner = true;
    const formDataUpload = new FormData();
    formDataUpload.append("attachment", this.file_store);
    this.uploadService
      .uploadFileStorage(formDataUpload)
      .subscribe((upload: FileUpload) => {
        if (upload.id !== '') {
          const user: Client = {
            name: this.basicGroup.get("name")?.value,
            employees: this.basicGroup.get("employees")?.value,
            identification: this.basicGroup.get("identification")?.value,
            web: this.contactGroup.get("web")?.value,
            sector: this.basicGroup.get("sector")?.value,
            service: this.formData.get("service")?.value,
            checkPolicies: this.formData.get("checkPolicies").value,
            city: this.controlCities.value,
            address: this.getControlAddress.value,
            phone: this.contactGroup.get("phone")?.value,
            emailContact: this.emailControl.value,
            attachment: upload,
            utmReferal: this.utmReferal ?? ''
          };
          this.formData.markAsPending();
          this.wizardService.wizardClientsServiceSend(user).subscribe({
            next: (res) => {
              this._snackBar.open(res.message, "Aceptar", {
                duration: 5000,
              });
              this.timerSpinner = false;
              this.formData.disabled;
              this.router.navigate(["onboard-thank"]);
            },
            error: (err) => {
              console.log(err);
              this._snackBar.open(err.error.message, "Aceptar", {
                duration: 5000,
              });
              this.timerSpinner = false;
            },
          });
        }
      });
  }

  get basicGroup(): FormControl {
    return this.formData.get("basic") as FormControl;
  }

  get getControlIdentification(): FormControl {
    return this.basicGroup.get('identification') as FormControl
  }

  get contactGroup(): FormControl {
    return this.formData.get("contact") as FormControl;
  }

  get emailControl(): FormControl {
    return this.contactGroup.get("emailContact") as FormControl;
  }
  get phoneCheck(): FormControl {
    return this.contactGroup.get("phone") as FormControl;
  }

  get nameControl(): FormControl {
    return this.formData.get("name") as FormControl;
  }

  get getControlAddress(): FormControl {
    return this.formData.get("address") as FormControl;
  }

  get controlCities(): FormControl {
    return this.formData.get("cities") as FormControl;
  }

  handleFileInputChange(l: FileList): void {
    const fileName = this.formData.get("fileName");
    if (l.length) {
      const f = l[0];
      this.file_store = f;
      fileName.patchValue(`${f.name}`);
    } else {
      fileName.patchValue("");
    }
  }

  sectores: Sector[] = [
    {
      name: "Agricultura, plantaciones otros sectores rurales",
    },
    {
      name: "Alimentación, bebidas, tabaco Comercio",
    },
    {
      name: "Construcción",
    },
    {
      name: "Educación",
    },
    {
      name: "Fabricación de material de transporte",
    },
    {
      name: "Función pública",
    },
    {
      name: "Hotelería, restauración, turismo",
    },
    {
      name: "Industrias químicas",
    },
    {
      name: "Ingenieria mecánica y eléctria",
    },
    {
      name: "Medios de comunicación cultura, gráficos",
    },
    {
      name: "Minería (carbón, otra minería)",
    },
    {
      name: "Petroleo y producción de gas refinación de petroleo",
    },
    {
      name: "Producción de metales básicos",
    },
    {
      name: "Servicios de correos y de telecomunicaciones",
    },
    {
      name: "Servicios de salud",
    },
    {
      name: "Servicios financieros servicios profesionales",
    },
    {
      name: "Servicios públicos agua, gas, electricidad",
    },
    {
      name: "Silvicultura, madera celulosa, papel",
    },
    {
      name: "Textiles, vestido, cuero, calzado",
    },
    {
      name:
        "Transporte (inluyendo aviación civil, ferrocarriles, transporte" +
        "por carretera",
    },
    {
      name: "Transporte marítimo, puertos pesca, transporte interior",
    },
    {
      name: "Otros",
    },
  ];

  servicesSwayp: Service[] = [
    {
      name: "Última milla",
    },
    {
      name: "Fullfilment",
    }
  ];

  openDialog() {
    const dialogRef = this.dialog.open(HabeasDataComponent);
    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }
}
