import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, map, tap } from 'rxjs';
import { Credentials, UserLogin } from '../models/userLogin.interface';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private user = new BehaviorSubject<UserLogin | null>(null);
  user$ = this.user.asObservable();
  isLoggedIn$: Observable<boolean> = this.user$.pipe(map(Boolean));


  constructor(private httpClient: HttpClient) {
    this.loadUserFromLocalStorage()
   }

  logIn() : Observable<UserLogin>{
    const credentials: Credentials = {
      id: environment.API_ID,
      email:environment.API_EMAIL,
      password:environment.API_PASSWORD
    }
    
    return this.httpClient.post<UserLogin>(`${environment.API_URL}v1/login`, credentials)
      .pipe(
        tap((userToken) => this.setLocalStorageStrategy(userToken)),
        tap((userToken) => this.pushNewUser(userToken))      
      )
  }

  private async pushNewUser(token: UserLogin) {
    this.user.next(token)
  }

  private loadUserFromLocalStorage(): void {
    const userFromLocal = localStorage.getItem('user');
    if(userFromLocal) {
      const user = JSON.parse(userFromLocal) as UserLogin
      userFromLocal && this.pushNewUser(user);
    }
  }

  private setLocalStorageStrategy(userLogin: UserLogin): void {
    localStorage.setItem('user', JSON.stringify(userLogin))
  }
  private removeLocalStorageStrategy(): void {
    localStorage.removeItem('user')
  }
}
