<!-- Navbar STart -->
<header id="topnav" class="defaultscroll sticky">
  <div class="container">
    <!-- Logo container-->
    <div>
      <a class="logo" routerLink="/">
        <img src="assets/images/logo-dark.png" height="34" alt="">
      </a>
    </div>
    <div class="menu-extras">
      <div class="menu-item">
        <!-- Mobile menu toggle-->
        <a class="navbar-toggle" (click)="toggleMenu()" [ngClass]="{'open': isCondensed === true}">
          <div class="lines">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </a>
        <!-- End mobile menu toggle-->
      </div>
    </div>

  <div id="navigation">      
      <ul class="navigation-menu">
        <li class="has-submenu" routerLink="/">
          <a href="javascript: void(0);">Inicio</a>
        </li>
        <li class="has-submenu" routerLink="/tracking">
          <a href="javascript: void(0);">Rastrea tu envío</a>
        </li>    
        <li class="has-submenu" routerLink="/team-delivery">
          <a href="javascript: void(0);">Ser mensajero</a>
        </li>    
        <li class="has-submenu" routerLink="/team-clients">
          <a href="javascript: void(0);">Contactar</a>
        </li>
      </ul>    
    </div>
  </div>
  <!--end container-->
</header>