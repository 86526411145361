import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-thankyou',
  templateUrl: './page-thankyou.component.html',
  styleUrls: ['./page-thankyou.component.css']
})

/**
 * Page Thankyou Component
 */
export class PageThankyouComponent implements OnInit {

  
  constructor() { }

  ngOnInit(): void {
  }

}
