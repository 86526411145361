import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { StatusGuides } from 'src/app/tracking-guides/model/statusGuides.class';
import { ServicesService } from 'src/app/services/services.service';
import { LoaderService } from 'src/app/shared/loader/loader.service';

@Component({
  selector: 'app-tracking',
  templateUrl: './tracking.component.html',
  styleUrls: ['./tracking.component.css']
})
export class TrackingComponent implements OnInit{
  @ViewChild('guideM') guideM: ElementRef;

  // Set Topbar Option
  
  appicons = true;
  isCondensed = false;
  currentSection = 'home';
  // Set Topbar Option   
  // Set Topbar Option
  Menuoption = 'center';
  Settingicon = true
  /**
   * Member Data
   */

  cargando = false;
  guia: any = null;
  datos: any = {};
  
  estadoFinal = '';
  conf = false;
  proc = false;
  tran = false;
  nove = false;
  dev = false;
  devConfir = false;
  entr = false;
  anul = false;  
  sol = false;
  asig = false;
  fechaConfirmado = '';
  fechaProcesado = '';
  fechaTransito = '';
  fechaNovedad = '';
  fechaSolucionado = '';
  fechaDevolucion = '';
  fechaAnulado = '';
  fechaEntregado = ''
  tienda = 'Prueba';
  whatsapp = '3010101012'
  verTienda = false;

  constructor(
    private services: ServicesService,
    public ngZone: NgZone,
    public loaderService: LoaderService,
  ) { }

  async ngOnInit() {

    const ruta = window.location;
    // this.utils.nativateToRouteCompany(ruta);
    const rutaTotal = ruta.pathname.split('/');

    this.guia = Number(rutaTotal[2]) || null;

    this.traerDatos();
  }

  async traerDatos() {

    this.cargando = true;
    // this.loaderService.showLoader('first');
    this.loaderService.showLoader('first');

    if (this.guia === null) {
      this.cargando = false;
      this.datos = null;
      return;
    }

    this.estadoFinal = '';
    this.conf = false;
    this.proc = false;
    this.tran = false;
    this.nove = false;
    this.anul = false;    
    this.sol = false;
    this.asig = false; 
    this.entr = false;
    this.devConfir = false;
    this.fechaConfirmado = '';
    this.fechaProcesado = '';
    this.fechaSolucionado = '';
    this.fechaTransito = '';
    this.fechaNovedad = '';
    this.fechaAnulado = '';
    this.fechaDevolucion = '';
    this.fechaEntregado = ''
    this.tienda = '';
    this.whatsapp = '';
    this.verTienda = false;

    

    await this.services.getGuide(this.guia).then(res => {
      if (res.length > 0) {
        this.datos = res[0];

        this.tienda = this.datos.tienda;
        this.whatsapp = this.datos.whatsapp || '';
        console.log(this.datos)
        switch (this.datos.estado) {
          case StatusGuides.confirmado:
            // siempre es verde
            this.estadoFinal = StatusGuides.confirmado   
            this.conf = true;
            this.fechaConfirmado = this.datos.fechaPedido === '' ? '' : new Date(this.datos.fechaPedido).toISOString().split('T')[0] || '';
            this.verTienda = true;
            break;
            //Anulado > Cancelada
            case StatusGuides.Cancelada:           
            this.estadoFinal = StatusGuides.Cancelada
            this.conf = true;
            this.anul = true;
            this.fechaAnulado = this.datos.fechaCancelado === '' ? '' : new Date(this.datos.fechaCancelado).toISOString().split('T')[0] || '';
            this.verTienda = true;
            break;
          case StatusGuides.Procesado:
            // siempre es verde
            this.estadoFinal = StatusGuides.Procesado
            this.conf = true;
            this.proc = true;           
            this.fechaProcesado = this.datos.fechaProcesado === '' ? '' : new Date(this.datos.fechaProcesado).toISOString().split('T')[0] || '';
            break;            
            //Comprado > Asginada
          case StatusGuides.asignada:
            this.estadoFinal = StatusGuides.asignada
            this.conf = true;
            this.proc = true;
            this.asig = true;
            this.datos.fechaNovedad ? this.nove = true : this.nove = false;
            this.fechaProcesado = this.datos.fechaProcesado === '' ? '' : new Date(this.datos.fechaProcesado).toISOString().split('T')[0] || '';
            this.verTienda = true;
            break;
            //Recogido > Reparto
          case StatusGuides.reparto:
            this.estadoFinal = StatusGuides.reparto
            this.conf = true;
            this.proc = true;
            this.asig = true;
            this.tran = true;
            this.fechaTransito = this.datos.fechaReparto === '' ? '' : new Date(this.datos.fechaReparto).toISOString().split('T')[0] || '';
            this.verTienda = true;
            break;
          case StatusGuides.novedad:
            this.estadoFinal = StatusGuides.novedad
            this.conf = true;
            this.proc = true;    
            this.asig = true;        
            this.datos.fechaNovedad ? this.nove = true : this.nove = false;
            this.fechaNovedad = this.datos.fechaNovedad === '' ? '' : new Date(this.datos.fechaNovedad).toISOString().split('T')[0] || '';
            this.verTienda = true;
            break;  
            case StatusGuides.solucionado:
            // siempre es verde
            this.estadoFinal = StatusGuides.solucionado
            this.conf = true;
            this.proc = true;
            this.asig = true;
            this.sol = true;
            this.nove = true;
            this.fechaSolucionado = this.datos.fechaSolucionado === '' ? '' : new Date(this.datos.fechaSolucionado).toISOString().split('T')[0] || '';
            break;
            case StatusGuides.devolucionConfirmada:
            this.estadoFinal = StatusGuides.devolucionConfirmada
            this.conf = true;
            this.proc = true;
            this.tran = true;
            this.asig = true;
            this.dev = true;            
            this.devConfir = true;
            this.fechaDevolucion = this.datos.fechaDevolucion === '' ? '' : new Date(this.datos.fechaDevolucion).toISOString().split('T')[0] || '';
            this.verTienda = true;
            break;
            case StatusGuides.devolucion:
            this.estadoFinal = StatusGuides.devolucion
            this.conf = true;
            this.proc = true;
            this.tran = true;
            this.asig = true;
            this.datos.fechaDevolucion ? this.dev = true : this.dev = false;
            this.fechaNovedad = this.datos.fechaNovedad === '' ? '' : new Date(this.datos.fechaNovedad).toISOString().split('T')[0] || '';
            this.verTienda = true;
            break;
            //Entregado > Entregada
          case StatusGuides.entregada:
            this.estadoFinal = StatusGuides.entregada
            this.conf = true;
            this.proc = true;
            this.tran = true;
            this.asig = true;
            this.entr = true;
            this.datos.fechaNovedad ? this.nove = true : this.nove = false;            
            this.fechaEntregado = this.datos.fechaEntregada === '' ? '' : new Date(this.datos.fechaEntregada).toISOString().split('T')[0] || '';
            this.datos.fechaEntrega = this.fechaEntregado;
            this.verTienda = true;
            break;
          case 'Cancelada':
            console.log("Sunday");
            break;
          case 'Devuelto':
            console.log("Sunday");
            break;
          default:
            console.log("Invalid state");
        }

      } else {
        this.datos = null;
        this.guia = null;

        setTimeout(() => {
          // this.guideM.nativeElement.setFocus();
          document.getElementById("guideM").focus();
        }, 100);
      }

      this.cargando = false;
    });
  }
  toggleMenu() {
    this.isCondensed = !this.isCondensed;
    if (this.isCondensed) {
      document.getElementById('navigation').style.display = 'block';
    } else {
      document.getElementById('navigation').style.display = 'none';
    }
  }
  buscar(ev: any) {
    this.guia = ev;
    this.traerDatos();
  }

  buscarMas() {
    this.clear();

    setTimeout(() => {
      // this.guideM.nativeElement.setFocus();
      document.getElementById("guideM").focus();
    }, 100);
  }

  clear(){
    this.datos = null;
    this.guia = null;
    this.conf = false;
    this.dev = false;
    this.tran = false;
    this.devConfir = false;
    this.asig = false;
    this.entr = false;
  } 
}


