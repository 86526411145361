<!-- Hero Start -->
<section class="bg-half-170 bg-light d-table w-100 d-print-none" id="home">
  <div class="container">
    <div class="row mt-5 justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="pages-heading">
          <h4 class="title">Políticas de protección de datos personales</h4>
        </div>
      </div> <!--end col-->
    </div><!--end row-->

    <div class="position-breadcrumb">
      <nav aria-label="breadcrumb" class="d-inline-block">
        <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
          <li class="breadcrumb-item"><a routerLink="/">Inicio</a></li>
          <li class="breadcrumb-item"><a href="javascript:void(0)">Políticas de protección de datos personales</a></li>
        </ul>
      </nav>
    </div>
  </div> <!--end container-->
</section><!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-color-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Start Privacy -->
<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-9">
        <div class="card shadow rounded border-0">
          <div class="card-body">
            <h2>Políticas de protección de datos personales</h2>
            <p>SWAYP se encuentra comprometida con la protección de los datos de todas las personas. De acuerdo con lo
              anterior, SWAYP manifiesta que garantiza los derechos de la privacidad, la intimidad y el buen nombre en
              el tratamiento de los datos personales, y en consecuencia todas sus actuaciones se regirán por los
              principios de legalidad, finalidad, libertad, veracidad o calidad, transparencia, acceso y circulación
              restringida, seguridad y confidencialidad. Todas las personas que suministren datos personales a SWAYP,
              podrán conocerla, actualizarla, rectificarla, suprimirla o revocar la autorización previamente otorgada.
            </p>

            <h4>Tratamiento de Datos Personales</h4>
            <p>El Tratamiento que llevarán a cabo los Responsables de las Bases de Datos se realizará conforme a lo
              establecido en la Ley 1581 de 2012, el Decreto 1377 de 2013 y las normas que lo adicionen o modifiquen,
              así como con lo establecido en la presente Política y las respectivas autorizaciones de Tratamiento de
              Datos Personales.

            <h4>Límite temporal del Tratamiento de Datos Personales</h4>
            <p>La Compañía dará Tratamiento a los Datos Personales por el término que sea razonable y necesario, el
              cual no será inferior al término de duración de la respectiva Compañía o de la relación contractual,
              legal o comercial que se tenga con el Titular de los Datos Personales. Una vez cese la finalidad o la
              necesidad del Tratamiento de los Datos Personales, los mismos serán eliminados de las Bases de Datos de
              la Compañía o serán archivados bajo medidas de seguridad adecuadas, a efectos de sólo ser divulgados
              cuando la ley así lo exija.</p>

            <h4>Transmisión y Transferencia de Datos Personales a terceros</h4>
            <p>SWAYP podrá transmitir o transferir los Datos Personales recolectados a terceros ubicados en colombia o
              en el exterior, incluyendo a sus aliados tecnológicos y comerciales, para el ejercicio de cualquiera de
              las finalidades antes descritas. En todo caso, SWAYP adoptará las medidas legales y tecnológicas para
              garantizar la seguridad y confidencialidad de los Datos Personales, y exigirá a los Encargados del
              Tratamiento el cumplimiento de los deberes establecidos en el artículo 18 de la Ley 1581 de 2012.</p>

            <p>Para la Transferencia de Datos Personales de los Titulares, SWAYP tomará las medidas necesarias para que
              los terceros conozcan y se comprometan a observar la presente Política, bajo el entendido de que la
              información personal que reciban únicamente podrá ser utilizada para asuntos directamente relacionados
              con SWAYP o los fines autorizados por el Titular de manera expresa, y solamente mientras esta
              autorización esté vigente.</p>

            <p>No podrá ser usada o destinada para propósitos o fines diferentes. También se celebrarán acuerdos de
              confidencialidad y se establecerán obligaciones Responsable-Encargado cuando el tipo de entrega lo
              amerite.</p>

            <h4>DERECHOS DEL TITULAR DE LOS DATOS PERSONALES</h4>
            <p>De conformidad con el artículo 8 de la Ley 1581 de 2012, el Titular de los Datos Personales tendrá los
              siguientes derechos:

              Acceder en forma gratuita a sus Datos Personales que sean objeto de Tratamiento.
              Solicitar prueba de la autorización otorgada a SWAYP, salvo cuando expresamente se exceptúe como
              requisito para el Tratamiento, de conformidad con lo previsto en el artículo 10 de la Ley 1581 de 2012.
              Ser informado, previa solicitud dirigida a SWAYP o al Encargado del Tratamiento, acerca del uso que se le
              ha dado a sus Datos Personales.

              Conocer, actualizar y rectificar sus Datos Personales frente a SWAYP o Encargados del Tratamiento. El
              derecho se podrá ejercer, entre otros, frente a datos parciales, inexactos, incompletos, fraccionados,
              que induzcan a error, o aquellos cuyo Tratamiento esté expresamente prohibido o no haya sido autorizado.
              Presentar solicitudes y reclamos relacionados con la regulación vigente en materia de Protección de
              Datos Personales.

              Revocar la autorización y/o solicitar la supresión de los Datos Personales, siempre que no exista un
              deber legal o contractual que impida eliminarlos.
              Presentar ante la Superintendencia de Industria y Comercio quejas por infracciones a lo dispuesto en la
              Ley 1581 de 2012 y las demás normas que la modifiquen, adicionen o complementen.
              Acceder en forma gratuita a sus Datos Personales que hayan sido objeto de Tratamiento.

              Abstenerse de dar respuesta a preguntas sobre Datos Sensibles, o sobre Datos Personales de los niños,
              niñas y adolescentes.</p>

          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End Privacy -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->