import { AbstractControl, AsyncValidatorFn, ValidationErrors } from "@angular/forms";
import { Observable, map } from "rxjs";
import { WizardService } from "src/app/services/wizard.service";

export function checkMessengerPhoneExistsConfirm (wizardService:WizardService) : AsyncValidatorFn {
    return (control:AbstractControl): Observable<ValidationErrors | null> => {        
        return wizardService
        .wizardMessengercheckPhoneExists(control.value)
        .pipe(
            map((messenger) => Boolean(messenger && messenger.phone === control.value) 
             ? { checkMessengerPhoneExistsConfirm : true} : null )
        )
    }
}

export function checkClientPhoneExistsConfirm (wizardService:WizardService) : AsyncValidatorFn {
    return (control:AbstractControl): Observable<ValidationErrors | null> => {        
        return wizardService
        .wizardClientsCheckPhoneExists(control.value)
        .pipe(
            map((client) => Boolean(client && client.phone === control.value) 
             ? { checkClientPhoneExistsConfirm : true} : null )
        )
    }
}