<app-header></app-header>
<div class="container-fluid wrapper-mat">
    <div class="col-banner col-md-6 col-12">
        <img class="banner-main" src="assets/images/clients.jpg" alt="" />
    </div>
    <div class="col-stepper col-md-6 col-12">
        <div class="row">
            <div class="header-stepper">
                <h2 class="title text-center">
                    Llevamos tu última milla<br />al siguiente nivel, una<br />experiencia de entrega diferente.
                </h2>
                <p class="text-center">
                    Ingresa tus datos y pronto nos pondremos<br />en contacto contigo.
                </p>
            </div>
        </div>
        <mat-divider></mat-divider>
        <mat-stepper linear #stepper class="stepper" [animationDuration]="200">
            <div class="row">
                <div class="col-md-8 col-12">
                    <form #f="ngForm" [formGroup]="formData">
                        <mat-step label="Datos básicos">
                            <div class="wrapper-step">
                                <div formGroupName="basic" class="input-section">
                                    <mat-form-field class="mat-input-section" appearance="outline">
                                        <mat-label>Nit/Rut</mat-label>
                                        <input class="form-control ps-5" matInput formControlName="identification"
                                            placeholder="Nit de la empresa o Identificación tributaria"
                                            appAddToCheckDigit required />
                                        <mat-error class="error-message"
                                            *ngIf="getControlIdentification.touched && getControlIdentification.hasError('required')">
                                            Este campo es requerido.
                                        </mat-error>
                                        <mat-error class="error-message"
                                            *ngIf="getControlIdentification.touched && getControlIdentification.hasError('minlength')">
                                            El tamaño mínimo es de 8 números
                                        </mat-error>
                                        <mat-error class="error-message"
                                            *ngIf="getControlIdentification.touched && getControlIdentification.hasError('pattern')">
                                            Debe incluir el dígito de verificación
                                        </mat-error>
                                    </mat-form-field>
                                    <mat-form-field class="mat-input-section" appearance="outline">
                                        <mat-label>Nombre de la organización</mat-label>
                                        <input class="form-control ps-5" matInput formControlName="name"
                                            placeholder="Nombre de la organización" required />
                                        <mat-error class="error-message">Este campo es requerido.</mat-error>
                                    </mat-form-field>
                                    <h3 class="subtitle">Categoría</h3>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Categoría</mat-label>
                                        <mat-select formControlName="sector" class="select-city">
                                            <mat-option *ngFor="let option of sectores" [value]="option.name">{{
                                                option.name }}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <h3 class="subtitle">Número de empleados</h3>
                                    <mat-radio-group formControlName="employees" class="employees">
                                        <mat-radio-button class="form-check-input form-check-input" value="1-10">1 a
                                            10</mat-radio-button>
                                        <mat-radio-button class="form-check-input form-check-input" value="10-20">10 -
                                            20</mat-radio-button>
                                        <mat-radio-button class="form-check-input form-check-input" value="+20">Más de
                                            20</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <div class="wrapper-btn">
                                    <button class="btn btn-primary" mat-button matStepperNext
                                        [disabled]="basicGroup.invalid">
                                        Siguiente
                                    </button>
                                </div>
                            </div>
                        </mat-step>
                        <mat-step label="Datos de contacto">
                            <div class="wrapper-step">
                                <div formGroupName="contact" class="input-section">
                                    <mat-form-field class="mat-input-section" appearance="outline">
                                        <ngx-mat-intl-tel-input style="height:40px;display:flex;align-items: center;"
                                            class="ngx-floating ng-dirty cdk-focused cdk-mouse-focused ng-touched"
                                            [preferredCountries]="['co']" [onlyCountries]="['co','us']"
                                            formControlName="phone" #phone required></ngx-mat-intl-tel-input>
                                        <mat-error class="error-message" *ngIf="phoneCheck.hasError('required')">Este
                                            campo es requerido.</mat-error>
                                        <mat-error class="error-message"
                                            *ngIf="phoneCheck.hasError('checkClientPhoneExistsConfirm')">Este teléfono
                                            ya está en uso, ingresa uno diferente.</mat-error>
                                    </mat-form-field>
                                    <mat-form-field class="mat-input-section" appearance="outline">
                                        <mat-label>Correo electrónico</mat-label>
                                        <input class="form-control ps-5" matInput formControlName="emailContact"
                                            placeholder="Correo eletrónico" required />
                                        <mat-error class="error-message" *ngIf="emailControl.hasError('email')">Este
                                            correo es invalido.</mat-error>
                                        <mat-error class="error-message" *ngIf="emailControl.hasError('required')">Este
                                            campo es
                                            requerido.</mat-error>
                                        <mat-error class="error-message"
                                            *ngIf="emailControl.hasError('checkClientsEmailExistsConfim')">Este correo
                                            ya está
                                            en uso, ingresa uno diferente.</mat-error>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Página web</mat-label>
                                        <input class="form-control ps-5" matInput formControlName="web"
                                            placeholder="Página web" required />
                                        <mat-error class="error-message">Este campo es requerido</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="wrapper-btn">
                                    <button class="btn btn-primary" mat-button matStepperPrevious>
                                        Anterior
                                    </button>
                                    <button class="btn btn-primary" mat-button matStepperNext
                                        [disabled]="contactGroup.invalid">Siguiente</button>
                                </div>
                            </div>
                        </mat-step>
                        <mat-step label="Zona de mensajería">
                            <div class="wrapper-step">
                                <div class="input-section">
                                    <mat-form-field class="mat-input-section" appearance="outline">
                                        <mat-label>Ciudad</mat-label>
                                        <input type="text" placeholder="Buscar ciudad para repartidor"
                                            aria-label="Number" matInput formControlName="cities"
                                            [matAutocomplete]="auto" [(ngModel)]="searchText">
                                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                            <mat-option *ngFor="let citie of cities$ | async | filterCities:searchText"
                                                [value]="citie.ciudad + ' ' + citie.nombreDepartamento">
                                                <span class="label-city">{{citie.ciudad | titlecase}}</span><span
                                                    class="label-depar"></span>{{citie.nombreDepartamento | titlecase}}
                                            </mat-option>
                                        </mat-autocomplete>
                                        <mat-error class="error-message" *ngIf="controlCities.hasError('required')">Este
                                            campo
                                            es requerido.</mat-error>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Dirección</mat-label>
                                        <input class="form-control ps-5" matInput formControlName="address"
                                            placeholder="Dirección de la empresa" required />
                                        <mat-error class="error-message">Este campo es requerido</mat-error>
                                    </mat-form-field>
                                    <mat-form-field class="mat-input-section" appearance="outline">
                                        <mat-label>Tipo de servicio de interés</mat-label>
                                        <mat-select formControlName="service" class="select">
                                            <mat-option *ngFor="let service of servicesSwayp" [value]="service.name">{{
                                                service.name }}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Adjuntar RUT</mat-label>
                                        <button class="icon-file" mat-icon-button matPrefix (click)="f_input.click()">
                                            <mat-icon>attach_file</mat-icon>
                                        </button>
                                        <input type="text" readonly matInput formControlName="fileName" />
                                        <input type="file" multiple hidden #f_input
                                            (change)="handleFileInputChange(f_input.files)" />
                                        <mat-error class="error-message">Este campo es requerido</mat-error>
                                    </mat-form-field>
                                    <p class="info-upload">
                                        (Tipo de archivo: pdf) Tamaño máximo del archivo 10MB
                                    </p>
                                    <mat-checkbox formControlName="checkPolicies">
                                        He leído y acepto las políticas de datos
                                        personales<button class="btn-null" mat-button (click)="openDialog()">Ver
                                            políticas</button></mat-checkbox>
                                    <div class="wrapper-btn">
                                        <button class="btn btn-primary" mat-button matStepperPrevious>
                                            Anterior
                                        </button>
                                        <button class="btn btn-primary" mat-button matStepperNext (click)="sendData()"
                                            [disabled]="(formData.statusChanges | async) !== 'VALID'">Envíar</button>
                                        <ng-container *ngIf="timerSpinner">
                                            <mat-spinner></mat-spinner>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </mat-step>
                    </form>
                </div>
            </div>
        </mat-stepper>
    </div>
</div>