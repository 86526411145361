<!-- Hero Start -->
<section class="bg-half-170 bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row mt-5 justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="pages-heading">
          <h4 class="title mb-0"> Terms of Services </h4>
        </div>
      </div> <!--end col-->
    </div><!--end row-->

    <div class="position-breadcrumb">
      <nav aria-label="breadcrumb" class="d-inline-block">
        <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
          <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
          <li class="breadcrumb-item"><a href="javascript:void(0)">Page</a></li>
          <li class="breadcrumb-item"><a href="javascript:void(0)">Utility</a></li>
          <li class="breadcrumb-item active" aria-current="page">Terms</li>
        </ul>
      </nav>
    </div>
  </div> <!--end container-->
</section><!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-color-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Start Terms & Conditions -->
<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-9">
        <div class="card shadow border-0 rounded">
          <div class="card-body">
            <h5 class="card-title">Introduction :</h5>
            <p class="text-muted">It seems that only fragments of the original text remain in the Lorem Ipsum texts used
              today. One may speculate that over the course of time certain letters were added or deleted at various
              positions within the text.</p>

            <h5 class="card-title">User Agreements :</h5>
            <p class="text-muted">The most well-known dummy text is the 'Lorem Ipsum', which is said to have <b
                class="text-danger">originated</b> in the 16th century. Lorem Ipsum is <b
                class="text-danger">composed</b> in a pseudo-Latin language which more or less <b
                class="text-danger">corresponds</b> to 'proper' Latin. It contains a series of real Latin words. This
              ancient dummy text is also <b class="text-danger">incomprehensible</b>, but it imitates the rhythm of most
              European languages in Latin script. The <b class="text-danger">advantage</b> of its Latin origin and the
              relative <b class="text-danger">meaninglessness</b> of Lorum Ipsum is that the text does not attract
              attention to itself or distract the viewer's <b class="text-danger">attention</b> from the layout.</p>
            <p class="text-muted">There is now an <b class="text-danger">abundance</b> of readable dummy texts. These
              are usually used when a text is <b class="text-danger">required purely</b> to fill a space. These
              alternatives to the classic Lorem Ipsum texts are often amusing and tell short, funny or <b
                class="text-danger">nonsensical</b> stories.</p>
            <p class="text-muted">It seems that only <b class="text-danger">fragments</b> of the original text remain in
              the Lorem Ipsum texts used today. One may speculate that over the course of time certain letters were
              added or deleted at various positions within the text.</p>

            <h5 class="card-title">Restrictions :</h5>
            <p class="text-muted">You are specifically restricted from all of the following :</p>
            <ul class="list-unstyled text-muted">
              <li>
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                Digital Marketing Solutions for Tomorrow
              </li>
              <li>
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                Our Talented & Experienced Marketing Agency
              </li>
              <li>
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                Create your own skin to match your brand
              </li>
              <li>
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                Digital Marketing Solutions for Tomorrow
              </li>
              <li>
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                Our Talented & Experienced Marketing Agency
              </li>
              <li>
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                Create your own skin to match your brand
              </li>
            </ul>

            <h5 class="card-title">Users Question & Answer :</h5>
            <div class="faq-content mt-4">
              <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0" closeOthers="true">
                <ngb-panel>
                  <ng-template ngbPanelTitle>
                    <div class="border-0 bg-light" id="headingone">
                      <h6 class="title mb-0"> How does it work ? </h6>
                    </div>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.
                    </p>
                  </ng-template>
                </ngb-panel>

                <ngb-panel>
                  <ng-template ngbPanelTitle>
                    <div class="border-0 bg-light" id="headingtwo">
                      <h6 class="title mb-0"> Do I need a designer to use Landrick ? </h6>
                    </div>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans">
                      There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.
                    </p>
                  </ng-template>
                </ngb-panel>
                <ngb-panel>
                  <ng-template ngbPanelTitle>
                    <div class="border-0 bg-light" id="headingfour">
                      <h6 class="title mb-0"> What do I need to do to start selling ? </h6>
                    </div>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans">
                      There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.
                    </p>
                  </ng-template>
                </ngb-panel>
                <ngb-panel>
                  <ng-template ngbPanelTitle>
                    <div class="border-0 bg-light" id="headingfive">
                      <h6 class="title mb-0">
                        What happens when I receive an order ?
                      </h6>
                    </div>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans">
                      There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.
                    </p>
                  </ng-template>
                </ngb-panel>
              </ngb-accordion>
            </div>

            <div class="mt-3">
              <a href="javascript:void(0)" class="btn btn-primary mt-2 me-2">Accept</a>
              <a href="javascript:void(0)" class="btn btn-outline-primary mt-2">Decline</a>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End Terms & Conditions -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->