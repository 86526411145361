import { Pipe, PipeTransform } from '@angular/core';
import { Cities } from '../models/area.interface';

@Pipe({
  name: 'filterCities'
})
export class FilterCitiesPipe implements PipeTransform {

  transform(value: Cities[], citie: string): Cities[] {
    if(!value || !citie){
      return []
    }
    return value.filter(value =>
      value.ciudad.includes(citie.toLocaleUpperCase())
    );
  }
}

