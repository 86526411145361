import { Injectable } from '@angular/core';
import { Zone } from '../models/zone.interface';

@Injectable({
  providedIn: 'root',
})
export class ZonesService {
  zones: Zone[] = [];

  constructor() {
    this.zones = [
      {
        id: 'CO-ANT',
        title: 'Antioquia',
        areas: [

          {
            "title": "Apartado"
          },
          {
            "title": "Carepa"
          },
          {
            "title": "Chigorodó"
          },
          {
            "title": "Caucasia"
          },
          {
            "title": "El Carmen de Viboral"
          },
          {
            "title": "Medellín"
          },
          {
            "title": "Itagüí"
          },
          {
            "title": "Caldas"
          },
          {
            "title": "La Estrella"
          },
          {
            "title": "San Antonio de Prado"
          },
          {
            "title": "Envigado"
          },
          {
            "title": "Sabaneta"
          },
          {
            "title": "Bello"
          },
          {
            "title": "Rionegro"
          },
          {
            "title": "La Ceja"
          },
          {
            "title": "Guarne"
          },
          {
            "title": "Marinilla"
          }
        ],
      },
      {
        id: 'CO-SAN',
        title: 'Santander',
        areas: [
          {
            title: 'Barrancabermeja',
          },
          {
            title: 'Bucaramanga',
          },
          {
            title: 'Floridablanca',
          },
          {
            title: 'Piedecuesta',
          },
          {
            title: 'Girón',
          },
          {
            title: 'San Gil',
          },
          {
            title: 'Pinchote'
          },
          // {
          //   title: 'Socorro'
          // },
          {
            title: 'Curiti'
          },
          {
            title: 'Valle De San Jose'
          },
          // {
          //   title: 'Paramo'
          // }
        ],
      },
      {
        id: 'CO-VAC',
        title: 'Valle del Cauca',
        areas: [
          {
            title: 'Buenaventura',
          },
          {
            title: 'Buga',
          },
          {
            title: 'Guacari',
          },
          {
            title: 'Ginebra',
          },
          // {
          //   title: 'El Cerrito',
          // },
          // {
          //   title: 'Restrepo',
          // },
          // {
          //   title: 'Darien',
          // },
          {
            title: 'Yotoco',
          },
          {
            title: 'Cali',
          },
          {
            title: 'Jamundi',
          },
          {
            title: 'Palmira',
          },
          {
            title: 'Candelaria',
          },
          // {
          //   title: 'Villa Gorgona',
          // },
          {
            title: 'Yumbo',
          },
          // {
          //   title: 'Montebello',
          // },
          {
            title: 'Cartago',
          },
          {
            title: 'Anserma Nuevo',
          },
          {
            title: 'Zaragoza',
          },
          {
            title: 'Obando',
          },
          // {
          //   title: 'Alcala',
          // },
          {
            title: 'Tuluá'
          },
          {
            title: 'Ponce'
          },
          {
            title: 'Sonso'
          },
          {
            title: 'Quebrada seca'
          }
          ,
          {
            title: 'Zanjohondo'
          }
        ],
      },
      {
        id: 'CO-BOY',
        title: 'Boyacá',
        areas: [
          {
            title: 'Tunja'
          },
          // {
          //   title: 'Motavita'
          // },
          // {
          //   title: 'Oicata'
          // },
          // {
          //   title: 'Combita'
          // },
          // {
          //   title: 'Chivata'
          // },
          // {
          //   title: 'Samaca'
          // },
          // {
          //   title: 'Soraca'
          // }
        ]
      },
      {
        id: 'CO-CES',
        title: 'Cesar',
        areas: [{
          title: 'Valledupar',
        },
          // {
          //   title: 'La Paz'
          // },
          // { title: 'San Diego' }
        ]
      },
      {
        id: 'CO-MET',
        title: 'Meta',
        areas: [{
          title: 'Villavicencio',
        }
        ]
      },
      {
        id: 'CO-ATL',
        title: 'Atlántico',
        areas: [
          {
            title: 'Barranquilla',
          },
          {
            title: 'Malambo',
          },
          {
            title: 'Soledad',
          },
        ],
      },
      {
        id: 'CO-LAG',
        title: 'La Guajira',
        areas: [
          {
            title: 'Riohacha',
          }
        ],
      },
      {
        id: 'CO-NAR',
        title: 'Nariño',
        areas: [
          {
            title: 'Pasto',
          },
          {
            title: 'Ipiales',
          },
          {
            title: 'Catanbuco',
          },
          {
            title: 'Jamondino',
          },
          {
            title: 'Pejesdino',
          },
          {
            title: 'San Juan de Agonoy',
          },
          {
            title: 'Obonuco',
          },
          {
            title: 'Juanoy alto y bajo',
          },
        ],
      },
      {
        id: 'CO-RIS',
        title: 'Risaralda',
        areas: [
          {
            title: 'Pereira',
          },
          {
            title: 'Dosquebradas',
          }, {
            title: 'Santa Rosa De Cabal',
          }
        ]
      },
      {
        id: 'CO-TOL',
        title: 'Tolima',
        areas: [
          {
            title: 'Ibagué',
          }
        ]
      },
      {
        id: 'CO-CAU',
        title: 'Cauca',
        areas: [
          {
            title: 'Popayán',
          }
        ]
      },
      {
        id: 'CO-PUT',
        title: 'Putumayo',
        areas: [
          {
            title: 'Mocoa',
          },
          {
            title: 'Villagarzon',
          },
        ],
      },
      {
        id: 'CO-BOL',
        title: 'Bolívar',
        areas: [
          {
            title: 'Cartagena',
          },
          {
            title: 'Turbaco',
          },
          {
            title: 'Arjona',
          },
        ],
      },
      {
        id: 'CO-QUI',
        title: 'Quindío',
        areas: [
          {
            title: 'Armenia',
          },
          {
            title: 'Caucasia',
          },
          {
            title: 'Malvinas',
          },
          {
            title: 'Paraguay de Caucasia',
          },
          // {
          //   title: 'Circasia',
          // },
          // {
          //   title: 'La Tebaida',
          // },
          // {
          //   title: 'Quimbaya',
          // },
          // {
          //   title: 'Montenegro',
          // },
        ],
      },
      {
        id: 'CO-COR',
        title: 'Córdoba',
        areas: [
          {
            title: 'Montería',
          },
          {
            title: 'Cerete',
          },
          {
            title: 'San Bernardo Del Viento',
          },
        ],
      },
      {
        id: 'CO-CAS',
        title: 'Casanare',
        areas: [
          {
            title: 'Yopal',
          }
        ],
      },
      {
        id: 'CO-CUN',
        title: 'Cundinamarca',
        areas: [
          {
            title: 'Bogotá'
          },
          {
            title: 'Facatativá',
          },
          {
            title: 'Fusagasugá',
          },
          {
            title: 'Arbelaez',
          },
          {
            title: 'Silvania',
          },
          {
            title: 'Chinauta',
          },
          {
            title: 'Pasca',
          },
          {
            title: 'Chía',
          },

          {
            title: 'Cajicá',
          },
          {
            title: 'Zipaquirá',
          },
          {
            title: 'Cogua',
          },
          {
            title: 'Soacha'
          }
        ],
      },
      {
        id: 'CO-MAG',
        title: 'Magdalena',
        areas: [
          {
            title: 'Ciénaga'
          },
          {
            title: 'Santa Marta'
          },
          {
            title: 'Gaira'
          },
          {
            title: 'Taganga'
          },
          {
            title: 'Bonda'
          },
          {
            title: 'Rodadero'
          },
        ]
      },
      {
        id: 'CO-SUC',
        title: 'Sucre',
        areas: [
          {
            title: 'Sincelejo',
          },
          // {
          //   title: 'Corozal',
          // },
          // {
          //   title: 'Sampues',
          // },
          // {
          //   title: 'Morroa',
          // },
          // {
          //   title: 'Chinu',
          // },
        ],
      },
      {
        id: 'CO-NSA',
        title: 'Norte de santander',
        areas: [
          {
            title: 'Cúcuta',
          },
          {
            title: 'Ocaña',
          },
          {
            title: 'Abrego',
          },
          {
            title: 'Rio De Oro',
          },
          // {
          //   title: 'Pueblo Nuevo',
          // }, 
          // {
          //   title: 'Buena Vista',
          // },
          // {
          //   title: 'Villa del Rosario',
          // },
          // {
          //   title: 'Los patios',
          // },
          // {
          //   title: 'Atalaya',
          // },
          {
            title: 'Playa de Belen',
          },
        ],
      },
      {
        id: 'CO-CAL',
        title: 'Caldas',
        areas: [
          {
            title: 'Manizales',
          },
          {
            title: 'Villamaria',
          },
          {
            title: 'La linda',
          },
        ],
      },
      {
        id: 'CO-HUI',
        title: 'Huila',
        areas: [
          {
            title: 'Neiva',
          },
          {
            title: 'Caguan',
          },
          {
            title: 'Rivera',
          },
          {
            title: 'Guacirco',
          },
          {
            title: 'Palermo',
          },
          {
            title: 'Riofrio',
          },
          {
            title: 'El Juncal',
          },
          {
            title: 'Fortalecillas',
          },
          {
            title: 'La Ullola',
          },
        ],
      },
      {
        id: 'CO-CAQ',
        title: 'Caquetá',
        areas: [
          {
            title: 'Florencia',
          }
        ],
      },
    ];
  }

  getZones() {
    return this.zones;
  }
}
