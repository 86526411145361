import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
} from "@angular/common/http";
import { Observable, first, switchMap } from "rxjs";
import { AuthService } from "src/app/services/auth.service";
import { environment } from "src/environments/environment";

@Injectable()
export class AuthInterceptorInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) { }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // CAPTURAR EL REQUEST AGREGAR EL TOKEN
    return this.authService.isLoggedIn$.pipe(
      first(),
      switchMap((isLoggedIn) => {
        if (isLoggedIn === false) {
          return next.handle(request);
        }

        return this.authService.user$.pipe(
          first(Boolean),
          switchMap(({ token }) => {

            let headers = new HttpHeaders({
              'Authorization': `Bearer ${token}`,
              'user': '1',
              'email': environment.API_EMAIL,            
              'Access-Control-Allow-Origin': '*'
            });

            return next.handle(request.clone({ headers }));
          })
        );
      })
    );
  }
}
