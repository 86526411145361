<!-- Hero Start -->
<section class="bg-half-170 bg-light d-table w-100" id="home">
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="pages-heading">
                    <h4 class="title mb-0"> Borders </h4>
                </div>
            </div> <!--end col-->
        </div><!--end row-->

        <div class="position-breadcrumb">
            <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                    <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Components</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Borders</li>
                </ul>
            </nav>
        </div>
    </div> <!--end container-->
</section><!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
    <div class="shape overflow-hidden text-color-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!--Shape End-->

<section class="section">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-4 d-md-block d-none">
                <app-component-sidebar></app-component-sidebar>
            </div>

            <div class="col-lg-9 col-md-8 col-12">
                <div class="row">
                    <!-- Border Start -->
                    <div class="col-12">
                        <div class="component-wrapper rounded shadow">
                            <div class="p-4 border-bottom">
                                <h5 class="title mb-0"> Borders </h5>
                            </div>

                            <div class="p-4">
                                <h6 class="text-muted mb-2 pb-2">Ex. 
                                    <pre><code class="text-danger">
&lt;span class="border"&gt;&lt;/span&gt;
&lt;span class="border-top"&gt;&lt;/span&gt;
&lt;span class="border-end"&gt;&lt;/span&gt;
&lt;span class="border-bottom"&gt;&lt;/span&gt;
&lt;span class="border-start"&gt;&lt;/span&gt;
</code></pre>
                                </h6>

                                <div class="d-inline-block me-1 mt-2">
                                    <p class="avatar avatar-small border bg-light rounded mb-0"></p>
                                </div>

                                <div class="d-inline-block me-1 mt-2">
                                    <p class="avatar avatar-small border-top bg-light rounded mb-0"></p>
                                </div>

                                <div class="d-inline-block me-1 mt-2">
                                    <p class="avatar avatar-small border-end bg-light rounded mb-0"></p>
                                </div>

                                <div class="d-inline-block me-1 mt-2">
                                    <p class="avatar avatar-small border-bottom bg-light rounded mb-0"></p>
                                </div>

                                <div class="d-inline-block me-1 mt-2">
                                    <p class="avatar avatar-small border-start bg-light rounded mb-0"></p>
                                </div>
                            </div>
                        </div>
                    </div><!--end col-->
                    <!-- Border End -->

                    <!-- Border Start -->
                    <div class="col-12 mt-4 pt-2">
                        <div class="component-wrapper rounded shadow">
                            <div class="p-4 border-bottom">
                                <h5 class="title mb-0"> Border Width </h5>
                            </div>

                            <div class="p-4">
                                <h6 class="text-muted mb-2 pb-2">Ex. 
                                    <pre><code class="text-danger">
&lt;span class="border border-1"&gt;&lt;/span&gt;
&lt;span class="border border-2"&gt;&lt;/span&gt;
&lt;span class="border border-3"&gt;&lt;/span&gt;
&lt;span class="border border-4"&gt;&lt;/span&gt;
&lt;span class="border border-5"&gt;&lt;/span&gt;
</code></pre>
                                </h6>

                                <div class="d-inline-block me-1 mt-2">
                                    <p class="avatar avatar-small border border-1 bg-light rounded mb-0"></p>
                                </div>

                                <div class="d-inline-block me-1 mt-2">
                                    <p class="avatar avatar-small border border-2 bg-light rounded mb-0"></p>
                                </div>

                                <div class="d-inline-block me-1 mt-2">
                                    <p class="avatar avatar-small border border-3 bg-light rounded mb-0"></p>
                                </div>

                                <div class="d-inline-block me-1 mt-2">
                                    <p class="avatar avatar-small border border-4 bg-light rounded mb-0"></p>
                                </div>

                                <div class="d-inline-block me-1 mt-2">
                                    <p class="avatar avatar-small border border-5 bg-light rounded mb-0"></p>
                                </div>
                            </div>
                        </div>
                    </div><!--end col-->
                    <!-- Border End -->

                    <!-- Border Start -->
                    <div class="col-12 mt-4 pt-2">
                        <div class="component-wrapper rounded shadow">
                            <div class="p-4 border-bottom">
                                <h5 class="title mb-0"> Border Subtractive </h5>
                            </div>

                            <div class="p-4">
                                <h6 class="text-muted mb-2 pb-2">Ex. 
                                    <pre><code class="text-danger">
&lt;span class="border-0"&gt;&lt;/span&gt;
&lt;span class="border border-top-0"&gt;&lt;/span&gt;
&lt;span class="border border-end-0"&gt;&lt;/span&gt;
&lt;span class="border border-bottom-0"&gt;&lt;/span&gt;
&lt;span class="border border-start-0"&gt;&lt;/span&gt;
</code></pre>
                                </h6>

                                <div class="d-inline-block me-1 mt-2">
                                    <p class="avatar avatar-small border-0 bg-light rounded mb-0"></p>
                                </div>

                                <div class="d-inline-block me-1 mt-2">
                                    <p class="avatar avatar-small border border-top-0 bg-light rounded mb-0"></p>
                                </div>

                                <div class="d-inline-block me-1 mt-2">
                                    <p class="avatar avatar-small border border-end-0 bg-light rounded mb-0"></p>
                                </div>

                                <div class="d-inline-block me-1 mt-2">
                                    <p class="avatar avatar-small border border-bottom-0 bg-light rounded mb-0"></p>
                                </div>

                                <div class="d-inline-block me-1 mt-2">
                                    <p class="avatar avatar-small border border-start-0 bg-light rounded mb-0"></p>
                                </div>
                            </div>
                        </div>
                    </div><!--end col-->
                    <!-- Border End -->

                    <!-- Border Start -->
                    <div class="col-12 mt-4 pt-2">
                        <div class="component-wrapper rounded shadow">
                            <div class="p-4 border-bottom">
                                <h5 class="title mb-0"> Border Color </h5>
                            </div>

                            <div class="p-4">
                                <h6 class="text-muted mb-2 pb-2">Ex. 
                                    <pre><code class="text-danger">
&lt;span class="border border-primary"&gt;&lt;/span&gt;
&lt;span class="border border-secondary"&gt;&lt;/span&gt;
&lt;span class="border border-success"&gt;&lt;/span&gt;
&lt;span class="border border-danger"&gt;&lt;/span&gt;
&lt;span class="border border-warning"&gt;&lt;/span&gt;
&lt;span class="border border-info"&gt;&lt;/span&gt;
&lt;span class="border border-light"&gt;&lt;/span&gt;
&lt;span class="border border-dark"&gt;&lt;/span&gt;
&lt;span class="border border-white"&gt;&lt;/span&gt;
</code></pre>
                                </h6>

                                <div class="d-inline-block me-1 mt-2">
                                    <p class="avatar avatar-small border border-primary bg-light rounded mb-0"></p>
                                </div>

                                <div class="d-inline-block me-1 mt-2">
                                    <p class="avatar avatar-small border border-secondary bg-light rounded mb-0"></p>
                                </div>

                                <div class="d-inline-block me-1 mt-2">
                                    <p class="avatar avatar-small border border-success bg-light rounded mb-0"></p>
                                </div>

                                <div class="d-inline-block me-1 mt-2">
                                    <p class="avatar avatar-small border border-danger bg-light rounded mb-0"></p>
                                </div>

                                <div class="d-inline-block me-1 mt-2">
                                    <p class="avatar avatar-small border border-warning bg-light rounded mb-0"></p>
                                </div>

                                <div class="d-inline-block me-1 mt-2">
                                    <p class="avatar avatar-small border border-info bg-light rounded mb-0"></p>
                                </div>

                                <div class="d-inline-block me-1 mt-2">
                                    <p class="avatar avatar-small border border-light rounded mb-0"></p>
                                </div>

                                <div class="d-inline-block me-1 mt-2">
                                    <p class="avatar avatar-small border border-dark bg-light rounded mb-0"></p>
                                </div>

                                <div class="d-inline-block me-1 mt-2">
                                    <p class="avatar avatar-small border border-white bg-light rounded mb-0"></p>
                                </div>
                            </div>
                        </div>
                    </div><!--end col-->
                    <!-- Border End -->
                </div><!--end row-->
            </div><!--end col-->
        </div>
    </div>
</section>

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
  </a>
  <!-- Back to top -->
