import { Component } from '@angular/core';

@Component({
  selector: 'app-ui-shadow',
  templateUrl: './ui-shadow.component.html',
  styleUrls: ['./ui-shadow.component.css']
})
export class UiShadowComponent {
  /**
   * Header button list show
   */
  Settingicon = true;
  Menuoption = 'center'
}
