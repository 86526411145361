<!-- Hero Start -->
<section class="bg-half-170 bg-light d-table w-100" id="home">
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="pages-heading">
                    <h4 class="title mb-0"> Lightbox </h4>
                </div>
            </div> <!--end col-->
        </div><!--end row-->

        <div class="position-breadcrumb">
            <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                    <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Components</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Lightbox</li>
                </ul>
            </nav>
        </div>
    </div> <!--end container-->
</section><!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
    <div class="shape overflow-hidden text-color-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!--Shape End-->

<section class="section">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-4 d-md-block d-none">
                <app-component-sidebar></app-component-sidebar>
            </div>

            <div class="col-lg-9 col-md-8 col-12">
                <div class="row">
                    <div class="col-12">
                        <div class="component-wrapper rounded shadow">
                            <div class="p-4 border-bottom">
                                <h5 class="title mb-0"> Image Lightbox </h5>
                            </div>

                            <div class="p-4">
                                <h6 class="text-muted mb-4 pb-2">Ex. <br> 
                                    <pre><code class="text-danger">
&lt;div class="card border-0 work-container work-primary work-grid position-relative d-block overflow-hidden rounded"&gt;
&lt;div class="card-body p-0"&gt;
&lt;a href="javascript:void(0);"&gt;
    &lt;img src="assets/images/work/11.jpg" class="img-fluid" alt="work-image"&gt;
&lt;/a&gt;
&lt;div class="content p-3"&gt;
    &lt;h5 class="mb-0"&gt;&lt;a routerLink="/portfolio-detail-one" class="text-dark title"&gt;Black and white T-shirt&lt;/a&gt;&lt;/h5&gt;
    &lt;h6 class="text-muted tag mb-0"&gt;Clothes&lt;/h6&gt;
&lt;/div&gt;
&lt;/div&gt;
&lt;/div&gt;
</code></pre>
                                </h6>
                                
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="card border-0 work-container work-primary work-grid position-relative d-block overflow-hidden rounded">
                                            <div class="card-body p-0">
                                                <a href="javascript:void(0);" (click)="openGallery('0')">
                                                    <img src="assets/images/work/11.jpg" class="img-fluid" alt="work-image">
                                                </a>
                                                <div class="content p-3">
                                                    <h5 class="mb-0"><a routerLink="/portfolio-detail-one" class="text-dark title">Black and white T-shirt</a></h5>
                                                    <h6 class="text-muted tag mb-0">Clothes</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div><!--end col-->

                    <div class="col-12 mt-4 pt-2">
                        <div class="component-wrapper rounded shadow">
                            <div class="p-4 border-bottom">
                                <h5 class="title mb-0"> Image Lightbox </h5>
                            </div>

                            <div class="p-4">
                                <h6 class="text-muted mb-4 pb-2">Ex. <br> 
                                    <pre><code class="text-danger">
&lt;div class="card border-0 work-container work-primary work-modern position-relative d-block overflow-hidden rounded"&gt;
&lt;div class="portfolio-box-img position-relative overflow-hidden"&gt;
&lt;img class="item-container img-fluid mx-auto" src="assets/images/personal/6.jpg" alt="1" /&gt;
&lt;div class="overlay-work"&gt;&lt;/div&gt;
&lt;div class="content"&gt;
    &lt;h5 class="mb-0"&gt;&lt;a routerLink="/portfolio-detail-one" class="text-white title"&gt;Mockup box with paints&lt;/a&gt;&lt;/h5&gt;
    &lt;h6 class="text-white-50 tag mt-1 mb-0"&gt;Photography&lt;/h6&gt;
&lt;/div&gt;
&lt;div class="icons text-center"&gt;
    &lt;a href="javascript:void(0)" class="work-icon bg-white d-inline-block rounded-pill lightbox"&gt;&lt;i-feather name="camera" class="fea icon-sm image-icon"&gt;&lt;/i-feather&gt;&lt;/a&gt;
&lt;/div&gt;
&lt;/div&gt;
&lt;/div&gt;
</code></pre>
                                </h6>

                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="card border-0 work-container work-primary work-modern position-relative d-block overflow-hidden rounded">
                                            <div class="portfolio-box-img position-relative overflow-hidden">
                                                <img class="item-container img-fluid mx-auto" src="assets/images/personal/6.jpg" alt="1" />
                                                <div class="overlay-work"></div>
                                                <div class="content">
                                                    <h5 class="mb-0"><a routerLink="/portfolio-detail-one" class="text-white title">Mockup box with paints</a></h5>
                                                    <h6 class="text-white-50 tag mt-1 mb-0">Photography</h6>
                                                </div>
                                                <div class="icons text-center">
                                                    <a href="javascript:void(0)" class="work-icon bg-white d-inline-block rounded-pill lightbox" (click)="openGallery('1')">
                                                        <i-feather name="camera" class="fea icon-sm image-icon"></i-feather></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div><!--end col-->
                </div><!--end row-->
            </div><!--end col-->
        </div>
    </div>
</section>

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
  </a>
  <!-- Back to top -->

