import { Directive, HostListener } from '@angular/core';
import { NgControl, Validators } from '@angular/forms';

@Directive({
  selector:
    '[formControlName][appAddToCheckDigit], [formControl][appAddToCheckDigit]',
})
export class AddToCheckDigitDirective {
  constructor(private ngControl: NgControl) { }

  @HostListener('blur', ['$event'])
  onBlur(event: Event): void {
    const control = this.ngControl.control
    if (control.dirty && !control.valid) {
      this.onBlurAddCheckDigit();
    }
  }

  onBlurAddCheckDigit() {
    let myNit = this.ngControl.control.value;

    let vpri: number[], x: number, y: number, z: number;

    // Se limpia el Nit
    myNit = myNit.replace(/\s/g, ''); // Espacios
    myNit = myNit.replace(/,/g, ''); // Comas
    myNit = myNit.replace(/\./g, ''); // Puntos
    myNit = myNit.replace(/-/g, ''); // Guiones

    // Se valida el nit
    if (isNaN(Number(myNit))) {
      console.log("El nit/cédula '" + myNit + "' no es válido(a).");
      return -1; // Cambiado a -1 para indicar un valor inválido
    }

    // Procedimiento
    vpri = new Array(16);

    vpri[1] = 3;
    vpri[2] = 7;
    vpri[3] = 13;
    vpri[4] = 17;
    vpri[5] = 19;
    vpri[6] = 23;
    vpri[7] = 29;
    vpri[8] = 37;
    vpri[9] = 41;
    vpri[10] = 43;
    vpri[11] = 47;
    vpri[12] = 53;
    vpri[13] = 59;
    vpri[14] = 67;
    vpri[15] = 71;

    z = myNit.length;
    x = 0;
    y = 0;

    for (let i = 0; i < z; i++) {
      y = Number(myNit.substr(i, 1));
      x += y * vpri[z - i];
    }

    y = x % 11;

    const result = y > 1 ? 11 - y : y;

    this.ngControl.control.setValue(`${myNit}${'-'}${result}`);
  }
}
