export class StatusGuides {
  static generada : string = "Generada";
  static confirmado : string = "Confirmado";
  static Cancelada : string = "Cancelada";
  static Procesado : string = "Procesado";    

  static asignada : string = "Asignada";  
  static reparto : string = "Reparto";
  static novedad : string = "Novedad"; 
  static solucionado : string = "Solucionado";
  
  static devolucion : string = "Devuelto";
  static devolucionConfirmada : string = "Devolucion Confirmada";

  static entregada : string = "Entregada";  
}