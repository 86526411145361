import { Component } from '@angular/core';

@Component({
  selector: 'app-ui-modals',
  templateUrl: './ui-modals.component.html',
  styleUrls: ['./ui-modals.component.css']
})
export class UiModalsComponent {
  /**
   * Header button list show
   */
  Settingicon = true;
  Menuoption = 'center'
}
