import { Component } from '@angular/core';

@Component({
  selector: 'app-course-detail',
  templateUrl: './course-detail.component.html',
  styleUrls: ['./course-detail.component.css']
})
export class CourseDetailComponent {
  // Set Topbar Option
  Menuoption = 'center';
  Settingicon = true;
  navClass = "nav-light";
}
