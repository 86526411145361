import { Component } from '@angular/core';

@Component({
  selector: 'app-ui-typography',
  templateUrl: './ui-typography.component.html',
  styleUrls: ['./ui-typography.component.css']
})
export class UiTypographyComponent {
  /**
   * Header button list show
   */
  Settingicon = true;
  Menuoption = 'center'
}
