import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { Cities } from '../models/area.interface';

@Injectable({
    providedIn: 'root'
})
export class ServicesService {

    notificaci = [];

    url = environment.API_URL;

    constructor(
        private httpClient: HttpClient,
        private router: Router
    ) {
    }

    // ************************************************************ PRODUCTS ********************************************************************

    async getGuide(datos: any) {
        return this.httpClient.get(this.url + 'v1/guides/track/' + datos)
            .toPromise()
            .then(async (res) => {
                return res;
            }).catch(err => {
                return err;
            });
    }

    getCities(token: string) {
        if (!token) {
            throw new Error('No tienes un token disponible');
        }

        const defaultCity = {
            id: '1',
            nombre: 'Colombia',
            bandera: 'flag-icon flag-icon-co',
            codigo: '57',
            prefix: 'CO',
            offset: -5,
            codigoMoneda: 'COP',
            nombreMoneda: 'Peso Colombiano',
            simboloMoneda: '$',
            simboloDecimal: '1.0-0'
        }  

        return this.httpClient.post<Cities[]>(this.url + 'v1/cities/all/', defaultCity, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'user': '1',
                'email': environment.API_EMAIL,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            }
        })
    }

}