<div class="back-to-home">
    <a routerLink="/index" class="back-button btn btn-icon btn-primary"><i-feather name="arrow-left" class="icons"></i-feather></a>
</div>

<section class="bg-home d-flex align-items-center position-relative" style="background: url('assets/images/shape01.png') center;">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="card form-signin p-4 rounded shadow">
                    <form>
                        <a routerLink="/index"><img src="assets/images/logo-icon.png" class="avatar avatar-small mb-4 d-block mx-auto" alt=""></a>
                        <h5 class="mb-3 text-center">Register your account</h5>
                    
                        <div class="form-floating mb-2">
                            <input type="text" class="form-control" id="floatingInput" placeholder="Harry">
                            <label for="floatingInput">First Name</label>
                        </div>

                        <div class="form-floating mb-2">
                            <input type="email" class="form-control" id="floatingEmail" placeholder="name@example.com">
                            <label for="floatingEmail">Email Address</label>
                        </div>

                        <div class="form-floating mb-3">
                            <input type="password" class="form-control" id="floatingPassword" placeholder="Password">
                            <label for="floatingPassword">Password</label>
                        </div>
                    
                        <div class="form-check mb-3">
                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                            <label class="form-check-label" for="flexCheckDefault">I Accept <a href="javascript:void(0)" class="text-primary">Terms And Condition</a></label>
                        </div>
        
                        <button class="btn btn-primary w-100" type="submit">Register</button>

                        <div class="col-12 text-center mt-3">
                            <p class="mb-0 mt-3"><small class="text-dark me-2">Already have an account ?</small> <a routerLink="/auth-bs-login" class="text-dark fw-bold">Sign in</a></p>
                        </div><!--end col-->

                        <p class="mb-0 text-muted mt-3 text-center">© {{year}} Landrick.</p>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
