<!-- Hero Start -->
<section class="bg-half-170 bg-light d-table w-100" id="home">
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="pages-heading">
                    <h4 class="title mb-0"> Icons </h4>
                </div>
            </div> <!--end col-->
        </div><!--end row-->

        <div class="position-breadcrumb">
            <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                    <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Components</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Icons</li>
                </ul>
            </nav>
        </div>
    </div> <!--end container-->
</section><!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
    <div class="shape overflow-hidden text-color-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!--Shape End-->

<section class="section">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-4 d-md-block d-none">
                <app-component-sidebar></app-component-sidebar>
            </div>

            <div class="col-lg-9 col-md-8 col-12">
                <div class="row">
                    <!-- Icons Start -->
                    <div class="col-12">
                        <div class="component-wrapper rounded shadow">
                            <div class="p-4 border-bottom">
                                <h5 class="title mb-0"> Icons </h5>
                            </div>

                            <div class="p-4">
                                <h6>Feather Icons</h6>
                                <p class="text-muted">There are 280+ feather icons and you can get all icons info from here: <a href="https://feathericons.com/" target="_blank"><code class="text-primary">https://feathericons.com/</code></a></p>
                                <h6 class="text-muted mb-4 pb-2">Ex. <code class="text-danger">&lt;i-feather name="mail" class="fea icon-sm"&gt; &lt;/i-feather&gt;</code></h6>

                                <i-feather name="user" class="fea icon-ex-md me-2"></i-feather>
                                <i-feather name="facebook" class="fea icon-ex-md me-2"></i-feather>
                                <i-feather name="map-pin" class="fea icon-ex-md me-2"></i-feather>
                                <i-feather name="linkedin" class="fea icon-ex-md me-2"></i-feather>
                                <i-feather name="camera" class="fea icon-ex-md me-2"></i-feather>
                                <i-feather name="mail" class="fea icon-ex-md me-2"></i-feather>

                                <h6 class="mt-4">Material Design Icons</h6>
                                <p class="text-muted">There are 4400+ Material Design icons and you can get all icons info from here: <a href="https://materialdesignicons.com/" target="_blank"><code class="text-primary">https://materialdesignicons.com/</code></a></p>
                                <h6 class="text-muted mb-4 pb-2">Ex. <code class="text-danger">&lt;i class="mdi mdi-home"&gt; &lt;/i&gt;</code></h6>

                                <i class="mdi mdi-home h4 me-2"></i>
                                <i class="mdi mdi-facebook h4 me-2"></i>
                                <i class="mdi mdi-chevron-tight h4 me-2"></i>
                                <i class="mdi mdi-camera-image h4 me-2"></i>
                                <i class="mdi mdi-car-light-high h4 me-2"></i>
                                <i class="mdi mdi-silverware-fork-knife h4 me-2"></i>

                                <h6 class="mt-4">Flaticon Icons</h6>
                                <p class="text-muted">There are Flaticon icons and you can get all icons info from here: <a href="https://www.flaticon.com/packs/basic-icon" target="_blank"><code class="text-primary">https://www.flaticon.com/packs/basic-icon</code></a></p>
                                
                                <img src="assets/images/icon/Email.svg" height="50" class="me-3" alt="">
                                <img src="assets/images/icon/bitcoin.svg" height="50" class="me-3" alt="">
                                <img src="assets/images/icon/calendar.svg" height="50" class="me-3" alt="">
                                <img src="assets/images/icon/location.svg" height="50" class="me-3" alt="">

                                <h6 class="mt-4">Iconscout (Unicons) Icons</h6>
                                <p class="text-muted">There are 1100+ Iconscout (Unicons) Icons and you can get all icons info from here: <a href="https://iconscout.com/unicons" target="_blank"><code class="text-primary">https://www.flaticon.com/packs/basic-icon</code></a></p>
                                <h6 class="text-muted mb-4 pb-2">Ex. <code class="text-danger"><br>&lt;span class="h3 text-primary me-2"&gt;<br>&nbsp; &lt;i class="uil uil-0-plus"&gt;&lt;/i&gt;<br>&lt;/span&gt;</code></h6>

                                <span class="h3 text-primary me-2"><i class="uil uil-0-plus"></i></span>
                                <span class="h3 text-primary me-2"><i class="uil uil-android"></i></span>
                                <span class="h3 text-primary me-2"><i class="uil uil-bitcoin"></i></span>
                                <span class="h3 text-primary me-2"><i class="uil uil-calendar-alt"></i></span>
                                <span class="h3 text-primary"><i class="uil uil-chart-pie-alt"></i></span>
                            </div>
                        </div>
                    </div><!--end col-->
                    <!-- Icons End -->
                </div><!--end row-->
            </div><!--end col-->
        </div>
    </div>
</section>

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
  </a>
  <!-- Back to top -->

