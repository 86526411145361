// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,  
  API_TEST: 'http://localhost:5000/swayp-web-api/us-central1/app/api/',  
  API_URL:'https://api.swayp.co/',  
  API_PROD:'https://us-central1-swayp-web-api.cloudfunctions.net/app/api/',
  API_ID:'1',
  API_EMAIL:'dgarciar@gmail.com',
  API_PASSWORD:'Swayp2022Dic',
  urlPermitidas: 'http://localhost:3000, https://us-central1-swayp-co.cloudfunctions.net/api, https://swaypce.web.app, http://localhost:4200, http://localhost:4201'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
