import { Component } from '@angular/core';

@Component({
  selector: 'app-onboard-thank',
  templateUrl: './onboard-thank.component.html',
  styleUrls: ['./onboard-thank.component.css']
})
export class OnboardThankComponent {

}
